import HomeView from "../components/templates/HomeView";

const HomePage = () => {
  return (
    <>
      <HomeView/>
    </>
  );
};

export default HomePage;
