import ListView from "../components/templates/ListView";

const ListPage = () => {
  return (
    <>
      <ListView/>
    </>
  );
};

export default ListPage;
