import { ResumeView } from "../../components/templates/ResumeView";

const ViewPage = () => {
  return (
    <>
      <ResumeView />
    </>
  );
};

export default ViewPage;
