import FormView from "../components/templates/FormView";

const CreatePage = () => {
  return (
    <>
      <FormView/>
    </>
  );
};

export default CreatePage;
